import React from 'react'
import image1 from '../images/background-hero.jpg'

const Hero = () => {
    return (
            <div id='home' className='w-full h-screen'>
                <img src={image1} alt="bg" className='top-0 left-0 w-full h-screen object-cover'/>
                <div className='bg-black/60 absolute top-0 left-0 w-full h-screen'/>
                <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                    <div className="container text-center">
                        <h1 className="text-lg font-semibold uppercase text-primary md:text-xl">Company Profile
                        <span className="block font-bold text-3xl capitalize text-white lg:text-5xl lg:my-3">WE ARE FAMELY</span>
                        </h1>
                        <h2 className="font-medium text-secondary text-lg mb-3 lg:text-2xl">
                            PT. Fajarindo Megatama Group
                        </h2>
                        <p className="font-medium text-slate-100 mb-10 leading-relaxed text-md">
                        We are digital creative services company that prioritizes innovation and creativity. We explore the potential of the digital audience, collaborate with content creators, and partner with brands to create unique campaigns across various digital formats.
                        </p>
                        <a href="#contact" className="text-base font-bold bg-primary text-white py-3 px-8 rounded-full hover:shadow-lg hover:opacity-80 transition duration-300 ease-in-out">
                            Contact Us
                            </a>
                    </div>
                </div>
            </div>    
            
    )
}

export default Hero
