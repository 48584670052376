import React from 'react'
import kemenag from '../images/kemenag.png'
import pln from '../images/pln.png'
import haka from '../images/haka.png'
import setel from '../images/setelevent.png'
import bsp from '../images/bsp.png'

const client = () => {
    return (
        <section id="client" className="pt-12 pb-12 bg-slate-700">
        <div className="container">
            <div className="w-full px-4">
                <div className="mx-auto text-center mb-16">
                    <h4 className="font-semibold text-lg text-primary mb-2 uppercase">Clients</h4>
                    <h2 className="font-bold text-white text-3xl mb-4 sm:text-4xl lg:text-4xl">Yang telah bekerjasama dengan Kami</h2>
                    {/* <p className="font-medium text-md text-secondary md:text-lg">Lorem ipsum dolor sit amet consectetur adipisicing elit. Id nam harum laudantium magni perferendis non!</p> */}
                </div>
            </div>
            <div className="w-full px-4 flex flex-wrap items-center justify-center">
                <div className="flex">
                    <a href="/" className="max-w-[120px] mx-4 py-4 grayscale opacity-60 transition hover:grayscale-0 hover:opacity-100 lg:mx-6 xl:mx-8">
                        <img src={bsp} alt="bsp"/>
                    </a>
                </div>
                <div className="flex">
                    <a href="/" className="max-w-[120px] mx-4 py-4 grayscale opacity-60 transition hover:grayscale-0 hover:opacity-100 lg:mx-6 xl:mx-8">
                        <img src={haka} alt="haka"/>
                    </a>
                </div>
                <div className="flex">
                    <a href="/" className="max-w-[120px] mx-4 py-4 grayscale opacity-60 transition hover:grayscale-0 hover:opacity-100 lg:mx-6 xl:mx-8">
                        <img src={setel} alt="setel"/>
                    </a>
                </div>
                <div className="flex">
                    <a href="/" className="max-w-[120px] mx-4 py-4 grayscale opacity-60 transition hover:grayscale-0 hover:opacity-100 lg:mx-6 xl:mx-8">
                        <img src={kemenag} alt="kemenag"/>
                    </a>
                </div>
                <div className="flex">
                    <a href="/" className="max-w-[120px] mx-4 py-4 grayscale opacity-60 transition hover:grayscale-0 hover:opacity-100 lg:mx-6 xl:mx-8">
                        <img src={pln} alt="pln"/>
                    </a>
                </div>
            </div>
        </div>
    </section>
    )
}

export default client