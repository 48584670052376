import React from 'react'

const about = () => {
    return (
        <section id="about" className="pt-12 pb-12">
        <div className="container">
            <div className="flex flex-wrap">
                <div className="w-ful px-4 mb-10 lg:w-1/2">
                    <h4 className="font-bold uppercase text-primary text-lg mb-3">About Us</h4>
                    <h2 className="font-bold text-dark text-3xl mb-5 max-w-lg lg:text-4xl">Company History</h2>
                    <p className="font-medium text-base text-secondary text-justify max-w-xl lg:text-lg">PT. Fajarindo Megatama Group didirikan pada tanggal 19 Juni 2023 berdasarkan akta notaris No. 101 oleh Imron, S.H., Notaris di Tangerang. Akta pendirian ini telah disahkan oleh Menteri Kehakiman Republik Indonesia pada tanggal 23 Juni 2023 dengan Surat Keputusan Nomor AHU-0045731.AH.01.01.Tahun 2023 dan merupakan perusahaan yang bergerak dibidang Jasa Digital Kreatif.</p>
                </div>
                <div className="w-full px-4 text-center lg:w-1/2">
                    <h3 className="font-bold text-dark text-3xl mb-5 lg:text-4xl lg:pt-12">Achievement</h3>
                    <div className='text-center'>
                        <h1 className="text-4xl font-semibold uppercase text-primary md:text-4xl">2.5M+
                            <span className="block text-2xl capitalize text-secondary mb-5 lg:text-2xl lg:my-3">views</span>
                        </h1>
                        <h1 className="text-4xl font-semibold uppercase text-primary md:text-4xl">40K+
                            <span className="block text-2xl capitalize text-secondary lg:text-2xl lg:my-3">subs</span>
                        </h1>
                        <p className="font-medium text-sm text-secondary uppercase mt-5 mb-6 lg:text-md">Numbers taken from multi Platform</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default about