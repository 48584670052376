import React from 'react'
import service1 from '../images/service1.png'
import service2 from '../images/service2.png'
import service3 from '../images/service3.png'
import service4 from '../images/service4.png'


const service = () => {
    return (
        <section id="service" className="pt-12 pb-12 bg-slate-100">
        <div className="container">
            <div className="w-full px-4">
                <div className="max-w-xl mx-auto text-center mb-16">
                    <h4 className="font-semibold text-lg text-primary mb-2 uppercase">Services</h4>
                    <h2 className="font-bold text-dark text-3xl mb-4 sm:text-4xl lg:text-4xl">Our Businesses</h2>
                    {/* <p className="font-medium text-md text-secondary md:text-lg">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla nemo assumenda fuga aspernatur distinctio eaque earum deleniti possimus dolores dolore.</p> */}
                </div>
            </div>
            <div className="w-full px-4 flex flex-wrap justify-center xl:w-10/12 xl:mx-auto">
                <div className="mb-12 p-4 md:w-1/2">
                    <div className="rounded-md shadow-md overflow-hidden">
                        <img src={service1} alt="atk" width="w-full"/>
                    </div>
                    <h3 className="font-semibold text-xl text-dark mt-5 mb-3 text-center">Fame Creative</h3>
                    <p className="font-medium text-base text-secondary text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, amet!</p>
                </div>
                <div className="mb-12 p-4 md:w-1/2">
                    <div className="rounded-md shadow-md overflow-hidden">
                        <img src={service4} alt="atk" width="w-full"/>
                    </div>
                    <h3 className="font-semibold text-xl text-dark mt-5 mb-3 text-center">Fame Consulting</h3>
                    <p className="font-medium text-base text-secondary text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, amet!</p>
                </div>
                <div className="mb-12 p-4 md:w-1/2">
                    <div className="rounded-md shadow-md overflow-hidden">
                        <img src={service3} alt="atk" width="w-full"/>
                    </div>
                    <h3 className="font-semibold text-xl text-dark mt-5 mb-3 text-center">Fame Organizer</h3>
                    <p className="font-medium text-base text-secondary text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, amet!</p>
                </div>
                <div className="mb-12 p-4 md:w-1/2">
                    <div className="rounded-md shadow-md overflow-hidden">
                        <img src={service2} alt="atk" width="w-full"/>
                    </div>
                    <h3 className="font-semibold text-xl text-dark mt-5 mb-3 text-center">Fame Equipment</h3>
                    <p className="font-medium text-base text-secondary text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, amet!</p>
                </div>
            </div>
        </div>
    </section>
    )
}

export default service